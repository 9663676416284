














































import {Component, Vue} from 'vue-property-decorator';
import {AuthBackendAPI} from '@/logic/authentication/AuthBackendAPI';
import {ArkStatsBackendClient, LogDto, ResourceListDto} from '@/logic/ark-stats-backend/ArkStatsBackendClient';
import {ServerDto} from '@/logic/ArkServerManagerClient';
import moment from 'moment';

@Component({
    components: {}
})
export default class Logs extends Vue {

    private servers: ServerDto[] = [];
    private server: number|null = null;
    private searchText: string|null = null;
    private logFile: string|null = null;
    private createdAfter: string|null = null;
    private createdBefore: string|null = null;

    private searchTerms: {
        server: number|null,
        searchText: string|null,
        logFile: string|null,
        after: string|null,
        before: string|null
    } = {
        server: null,
        searchText: null,
        logFile: null,
        after: null,
        before: null
    };

    private logResults: ResourceListDto<LogDto>|null = null;

    public async search(): Promise<void> {
        this.logResults = null;
        Vue.set(this.searchTerms, 'server', this.server);
        Vue.set(this.searchTerms, 'searchText', this.searchText);
        Vue.set(this.searchTerms, 'after', this.createdAfter);
        Vue.set(this.searchTerms, 'before', this.createdBefore);
        Vue.set(this.searchTerms, 'logFile', this.logFile);
        this.logResults = (await this.doSearch());
    }

    private error: string|null = null;

    async created(): Promise<void> {
        this.servers = (await ArkStatsBackendClient.getGenericObject<ResourceListDto<ServerDto>>("server", { fields: ['id', 'name' ], orderBy: 'id'})).data;
    }

    public parsedDate(date: string): string {
        return date ? ('Interpreted as: ' + this.localDate(moment(date).toISOString(false))) : '';
    }

    public async doSearch(): Promise<ResourceListDto<LogDto>|null> {
        try {
            const filters = [];
            if(this.searchTerms.server) {
                filters.push(`serverId=${encodeURIComponent(this.searchTerms.server)}`);
            }
            if(this.searchTerms.after) {
                filters.push(`after=${encodeURIComponent(moment(this.searchTerms.after).toISOString(false))}`);
            }
            if(this.searchTerms.before) {
                filters.push(`before=${encodeURIComponent(moment(this.searchTerms.before).toISOString(false))}`);
            }
            if(this.searchTerms.searchText) {
                filters.push(`text=${encodeURIComponent(this.searchTerms.searchText)}`);
            }
            if(this.searchTerms.logFile) {
                filters.push(`logFile=${encodeURIComponent(this.searchTerms.logFile)}`);
            }
            filters.push('limit=5000');
            this.error = '';
            return (await ArkStatsBackendClient.get<ResourceListDto<LogDto>>(`logs/search?${filters.join('&')}`));
        } catch(ex) {
            const errorReason = ex?.response?.data?.error?.reason;
            if(!errorReason) {
                this.error = 'An unknown error occurred';
            } else {
                this.error = errorReason;
            }

            console.log(ex.response);
            throw ex;
        }
    }

    isAdmin(): boolean {
        return AuthBackendAPI.isAdmin(this.$store.state.currentUserState);
    }

    getAuthenticationLink(): string {
        return "https://auth.domination-gaming.com/oauth/discord?redirect_uri=" + encodeURIComponent(window.location.href);
    }

    localDate(timestamp: string | Date): string {
        const dateFormatOptions = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit'} as const;
        return new Intl.DateTimeFormat('en-US', dateFormatOptions).format(new Date(timestamp));
    }
}
